/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {
  src: url("assets/font/avenir.ttf");
  font-family: avenir;
}

*{
  font-family: avenir!important;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 24pt;
}

p{
  font-size: 12pt;
}

.font-primary-color{
  color: #0c3b5b!important;
}

.font-secondary-color{
  color: #d5c0ae!important;
}

.background-primary{
  background-color: #0c3b5b!important;
  --background-color: #0c3b5b !important;
}

.font-tertiary-color{
  color: #fff!important;
}

.menu-item{
  a{
    color: white;
    text-decoration: none;
  }
  font-size: 10pt;
  letter-spacing: 8px;
  text-align: center;
  cursor: pointer;
}
.h-100{
  height: 100%;
}
.w-100{
  width: 100%;
}
.w-05{
  width: 5%;
}
.w-30{
  width: 30%;
}

.w-65{
  width: 65%;
}

.d-inline-block{
  display: inline-block;
}


ion-toolbar{
  --background: #0c3b5b!important;
  --color: white!important;
  --opacity: 1!important;
}

ion-menu-button{
  --color: white!important;
}

.img-fit{
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
  }
}

.headline{
  line-height: 43pt;
  margin-bottom: .5rem;
}

.summary, #info-tholen
{
  text-align: center;
  margin: 0 auto;
  p{
    margin-top: 0;
    margin-bottom: 0;
    &.summary-text{
      line-height: 24pt;
    }
    &#text-line-1,&#text-line-10{
      margin: 0 auto;
      letter-spacing: 4pt;
      &.custom{
        margin: 0 auto 1rem;
      }
    }
    &#text-line-2,#text-line-20{
      line-height: 32pt;
    }
    &#text-line-4{

    }
    &#text-line-5{

    }
    &#text-line-6{

    }
    &#text-line-7{

    }
    &#text-line-8{

    }
    &#text-line-9{

    }
    &#text-line-10{

    }
    &#text-line-11{

    }
  }

  hr{
    background: #0c3b5b;
    margin: 1rem 0;
  }
}

.background-danger{
  background-color: rgb(255 113 113);
}

.background-success{
  background-color: rgb(210 209 182);
}

.summary-wave, .footer-waves{
  margin: 1rem auto;
  width: 60%;
  text-align: center;
}

.font-large{
  font-size: 32pt;
}
.font-medium{
  font-size: 15pt;
}
.font-small{
  font-size: 10pt;
}

ion-grid#info{
  ion-row{
    flex-wrap: wrap;
    align-items: center;
    background-color: #f7f3f0;
    position: relative;
    ion-col{
      line-height: 26px;
      height: 100%;
      &.slide-text.fullscreen{
        padding-left: 40px;
      }
      h1{
        padding-bottom: 19px;
      }
      div{
        ion-icon{
          font-size: 17pt;
          vertical-align: text-top;
        }
      }
    }
  }
}
.slide-wave-container{
  position:relative;
  align-items: center;
  .slide-wave{
    margin: 0;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .xxl{
      bottom: -20px;
    }
    z-index:2;
    width: 100%;
  }
}

.title.font-medium{
  font-weight: bold;
}

.container-fluid{
  width: 100%;
  position: relative;
  &.green{
    background-color: #bcba96;
    background-image: url("assets/img_backgr_rep_right_green_1.png");
    background-repeat: repeat-y;
    background-position: top right;
    &.fullscreen{
      background-image: url("assets/img_backgr_rep_left_green_1.png"), url("assets/img_backgr_rep_right_green_1.png");
      background-repeat: repeat-y, repeat-y;
      background-position: top left, top right;
    }
  }
  &.blue{
    background-color: #0c3b5b;
    background-image: url("assets/img_backgr_rep_right_blue_1.png");
    background-repeat: repeat-y;
    background-position: top right;
    &.fullscreen{
      background-image: url("assets/img_backgr_rep_left_blue_1.png"), url("assets/img_backgr_rep_right_blue_1.png");
      background-repeat: repeat-y, repeat-y;
      background-position: top left, top right;
    }
  }
  &.white{
    background-color: #fff;
    background-image: url("assets/img_backgr_rep_right_white_1.png");
    background-repeat: repeat-y;
    background-position: top right;
    &.fullscreen{
      background-image: url("assets/img_backgr_rep_left_white_1.png"), url("assets/img_backgr_rep_right_white_1.png");
      background-repeat: repeat-y, repeat-y;
      background-position: top left, top right;
    }
  }
}

.waves-white-left {
  background-image: url("assets/img_backgr_rep_left_white_1.png");
  background-repeat: repeat-y;
  background-position: top left;
  --background-image: url("assets/img_backgr_rep_left_white_1.png");
  --background-repeat: repeat-y;
  --background-position: top left;
}

.waves-white-right{
  background-image: url("assets/img_backgr_rep_right_white_1.png");
  background-repeat: repeat-y;
  background-position: top right;
  --background-image: url("assets/img_backgr_rep_right_white_1.png");
  --background-repeat: repeat-y;
  --background-position: top right;
}

ion-icon{
  font-size: 17pt;
  vertical-align: text-top;
}

.font-large.mobile{
  font-size:23pt;
}


.input-style{
  border: 4px solid rgb(210 209 182);
  background: rgb(255 255 255);
  border-radius: 5px;
  padding: 8px;
  color: #807d6d;
  height: 56px;
  margin-bottom: 1rem;
  &#input-message{
    height: auto!important;
  }
}

input[type='button']{
  background: #d2d1b6;
  color: #807d6d;
}

.align-items-flex-end{
  align-items: flex-end!important;
  display: flex;
}

.align-items-flex-start{
  align-items: flex-start!important;
  display: flex;
}

.vertical-align-middle {
  vertical-align: middle;
}

.align-content-flex-start{
  --align-content: flex-start!important;
  --display: flex!important;
  align-content: flex-start!important;
  display: flex!important;
}

.background-slider{
  background: #f7f3f0;
}

.margin-bottom-42{
  margin-bottom: 42px;
}

.line-height{
  line-height: 24pt;
}

.line-height-header{
  line-height: 32pt;
}

.slide-wave{
  &.large{
    position: relative;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    img{
      max-width: 300px;
    }
  }
}

.pointer{
  cursor: pointer;
}


.flex-start{
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-center{
  justify-content: center;
  align-items: center;
}

.flex-end{
  justify-content: flex-end;
  align-items: flex-end;
}



.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background-color: #0c3b5b!important;
}


.container-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12pt;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: rgb(210 209 182);
}

.text-decoration-privacy{
  text-decoration: underline;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
